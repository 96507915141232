import React from "react";
//import the css file for styling
import "./TenderFunding.scss";

//import assets from constants
import assets from "../../constants";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";

// Create a style object and set the `backgroundImage` property to the URL of the background image variable.
const styles = {
  backgroundImage: `url(${assets.bgpofinance1})`,
  opacity: 1,
  backgroundPosition: `top left`,
  bacgroundSize: "cover",
};
//create TenderFundingle
const TenderFunding = (props) => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  //let affilliateID = cookies.affid;
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  //Handle when user click Buy Button and redirect the user to a new url
  const handleRFQButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page

    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/RFQFormPOFinMarketPlace/formperma/xKZrj2_zBlwPWXvzhtCS5NWgDUcOdDhe4Ro7-dN4Lfk?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleApplyButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    //const rfqUrl ="https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaPurchaseOrderFundingApplication/formperma/KNHMcBROaHBa0Z8wkGlIi4nGxGybgEGFkbR7CPKs5Is?";
    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaPurchaseOrderFundingApplication/formperma/KNHMcBROaHBa0Z8wkGlIi4nGxGybgEGFkbR7CPKs5Is?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Chat Button and redirect the user to a new url
  const handleChat = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl = "https://api.whatsapp.com/message/EF2LXKA73QPGJ1";
    window.open(rfqUrl, "_blank");
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleNoCreditChecks = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://marketdirect-blog.vercel.app/posts/get-cheaper-bank-loans-in-future-with-marketdirect-co-za-tender-finance?";

    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  return (
    //Tender funding section and content
    <section id="TenderFinance">
      <div>
        <div className="row pt-5 align-items-right">
          <div className="col-sm p-3 order-1 align-self-start">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item">
                  <img
                    src={assets.womanincontructionblackandwhite}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Construction and Tender Finance for Women In Construction"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.womanincontructionblackinorangeppe}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Construction and Tender Finance for Women In Construction"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.womanincontructionblacksmiling}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Construction and Tender Finance for Women In Construction"
                  />
                </div>
                <div className="carousel-item active">
                  <img
                    src={assets.womanincontructiontired}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Construction and Tender Finance for Women In Construction"
                  />
                </div>
                {/* <div class="carousel-item">
                  <img
                    src={assets.tenderfunderclubgirlreading}
                    class="d-block w-100"
                    className="rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src={assets.tenderfunderclubboyinlibrary}
                    class="d-block w-100"
                    className="rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div> */}
              </div>
            </div>
            {/* <img
              src={assets.tenderfunderclubcutegirl}
              alt="Tender Funder Club"
              className="rounded img-fluid"
            /> */}
          </div>
          <div className="col-sm p-3 order-5 align-self-start">
            <h1 className="display-2 text-white text-left fw-bolder">
              {`Construction, Purchase Order and Tender Finance/Funding ${props.city}`}
            </h1>
            <p className="lead text-white text-left">
              <strong>
                Are you looking for capital or funding for your construction
                projects or delivery of goods?
              </strong>{" "}
              Whether you have a contract, tender or purchase order from a large
              company or government, we can help you get the money you need. You
              can access funding from R50 000 with no credit checks required.
              Just fill out our short request for quote form and get a quote
              from us today! To get started, click or tap the 'apply now' blue
              button below!
            </p>
            <div className="d-flex flex-column">
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleRFQButton}
                >
                  APPLY NOW PRESS HERE!
                </a>
              </div>
              <div className="p-10 text-center">OR</div>
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleNoCreditChecks}
                >
                  LEARN ABOUT NO CREDIT CHECKS FINANCE
                </a>
              </div>
              <div className="p-10 text-center">OR</div>
              <div className="pb-10 text-center">
                <a className="w-50 btn btn-lg btn-primary" onClick={handleChat}>
                  CHAT WITH AN EXPERT
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TenderFunding;
