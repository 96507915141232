import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import api from "../assets/api.png";
import cpp from "../assets/cpp.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import mu5 from "../assets/mu5.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logomdhorizontal from "../assets/logo.png";
import logomdsquare from "../assets/LogoMarketDirect.png";
import logomdtf from "../assets/LogoMarketDirectTenderFinance.png";
import logomdenergy from "../assets/LogoMarketDirectEnergySolution.png";
import logostokvelpay from "../assets/LogoStokvelPay.png";

import adidas from "../assets/adidas.png";
import amazon from "../assets/amazon.png";
import asus from "../assets/asus.png";
import bolt from "../assets/bolt.png";
import nb from "../assets/nb.png";
import skype from "../assets/skype.png";
import spotify from "../assets/spotify.png";

import mobileapp from "../assets/3CXMobileIPApp.png";
import YealinkSIPT33G from "../assets/YealinkSIPT33G.png";
//import audio sound called welcometobalistrom from "../assets/welcometobalistrom.mp3";
import Ayanda from "../assets/WelcomeToBalistrom.wav";
import ReferAndEarn from "../assets/ReferAndEarn.jpg";
//import favicon
import favicon from "../assets/favicon.ico";
//import background image named bg-business-woman-ok-gesture.jpg
import bgpofinance1 from "../assets/bg-business-woman-ok-gesture.jpg";
import googlereviewsicon from "../assets/googlereviews.png";
import tenderfunderclubboyinlibrary from "../assets/TenderFunderClubBoyInLibrary.jpg";
import tenderfunderclubcutegirl from "../assets/TenderFunderClubGirl.jpg";
import tenderfunderclubgirlreading from "../assets/TenderFunderClubGirlReading.jpg";
//TenderFunderClubRetiredBlackCoupleByPool
import tenderfunderclubretiredblackcouple from "../assets/TenderFunderClubRetiredBlackCoupleByPool.png";
import FurnitureRemoval from "../assets/FurnitureRemoval.jpg";
import FurnitureRemovalCase1 from "../assets/IMG_6287.JPG";
import FurnitureRemovalCase2 from "../assets/IMG_6293.JPG";
import FurnitureRemovalCase3 from "../assets/IMG_6294.JPG";
import FurnitureRemovalCase4 from "../assets/IMG_6301.JPG";
import substationsunset from "../assets/IMG_6316.png";
import substation2 from "../assets/IMG_6317.png";
import substation3 from "../assets/IMG_6318.png";
import handtools from "../assets/IMG_6320.png";
import womanincontructiontired from "../assets/ConstructionFinanceForWomenInConstruction (1).png";
import womanincontructionblackandwhite from "../assets/ConstructionFinanceForWomenInConstruction (2).png";
import womanincontructionblackinorangeppe from "../assets/ConstructionFinanceForWomenInConstruction (3).png";
import womanincontructionbusilookalike from "../assets/ConstructionFinanceForWomenInConstruction (4).png";
import womanincontructionblacksmiling from "../assets/ConstructionFinanceForWomenInConstruction (5).png";
import invest2xrdphouses from "../assets/2xRDPHouseDevelopment.png";
import investschooldemolition from "../assets/DemolitionofClassrooms.jpg";
import investwashingmachines from "../assets/WashingMachine.jpg";
import investsteeldoors from "../assets/Steel-Doors.jpg";
import investsteelgate from "../assets/Steel-Gate.jpg";
import investmentclosed from "../assets/InvestmentClosed.png";
import investsolar from "../assets/SolarSystemMDSOLAR20240119.jpg";
import investfreedomblackwomanreadonbeach from "../assets/FinancialFreedomBlackWomanReadingAtBeach.jpg";
import investfreedomblackyouthinyellow from "../assets/FinancialFreedomBlackYouthHappyInYellow.jpg";
import investfreedomoldblackmanhiking from "../assets/FinancialFreedomOldBlackManHiking.jpg";
import investfreedomoldwhitemanindroptop from "../assets/FinancialFreedomOldWhiteManInDropTopCar.jpg";
import investfreedompetiteblackwomanwalkonbeach from "../assets/FinancialFreedomPetiteBlackWomanWalkOnBeach.jpg";
import investentrepreneurblackwomaningreenshirt from "../assets/TenderFunderClubEntrepreneur.jpg";
import investcansofpaint from "../assets/InvestCansOfPaint.jpg";
import investpaintsoilheatersandmore from "../assets/MarketDirectTenderFunderClub-2-PaintHeatersAndMore.jpg";
import investSCENARIOB from "../assets/MarketDirectTenderFunderClubScenarioB.jpg";
import investSCENARIOC from "../assets/MarketDirectTenderFunderClub-4-SCENARIOC.jpg";
import investGroceryDelivery from "../assets/MarketDirectTenderFunderClubGroceryDelivery.jpg";
import investRDPHouseR120K from "../assets/BannerRDPHouseR120K.jpg";
import InvestSteelFramesManufacture from "../assets/InvestSteelFramesManufacture.jpg";
import InvestBankFundedHouseConstructionMaterial from "../assets/InvestBank-FundedHouseConstructionMaterial.jpg";
import InvestInvestRDPMaterialDeliveryOrder from "../assets/InvestRDPMaterialDeliveryOrder.jpg";
import InvestSchoolRenovationsMaterialSupply from "../assets/InvestSchoolRenovationsMaterialSupply.jpeg";
import MD76208LandRestoration from "../assets/MD76208LandRestoration.jpg";
import TwoPotRetirementSystem from "../assets/TwoPotRetirementSystem.jpg";
import TolaTenderProWinningWoman from "../assets/TolaTenderProWinningBlackWoman.png";
import TolaTenderProWinningMan from "../assets/TolaTenderProWinningBlackMan.png";
import TolaTenderProMaMkhize from "../assets/TolaTenderPro-3-MamMkhize.jpeg";

export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  //logo,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  logomdsquare,
  logomdtf,
  logomdenergy,
  logostokvelpay,
  logomdhorizontal,
  mobileapp,
  YealinkSIPT33G,
  Ayanda,
  ReferAndEarn,
  favicon,
  bgpofinance1,
  googlereviewsicon,
  tenderfunderclubboyinlibrary,
  tenderfunderclubcutegirl,
  tenderfunderclubgirlreading,
  tenderfunderclubretiredblackcouple,
  FurnitureRemoval,
  FurnitureRemovalCase1,
  FurnitureRemovalCase2,
  FurnitureRemovalCase3,
  FurnitureRemovalCase4,
  substationsunset,
  substation2,
  substation3,
  handtools,
  womanincontructiontired,
  womanincontructionblackandwhite,
  womanincontructionblackinorangeppe,
  womanincontructionblacksmiling,
  womanincontructionbusilookalike,
  invest2xrdphouses,
  investschooldemolition,
  investwashingmachines,
  investsteeldoors,
  investsteelgate,
  investmentclosed,
  investsolar,
  investfreedomblackwomanreadonbeach,
  investfreedomblackyouthinyellow,
  investfreedomoldblackmanhiking,
  investfreedomoldwhitemanindroptop,
  investfreedompetiteblackwomanwalkonbeach,
  investentrepreneurblackwomaningreenshirt,
  investcansofpaint,
  investpaintsoilheatersandmore,
  investSCENARIOB,
  investSCENARIOC,
  investGroceryDelivery,
  investRDPHouseR120K,
  InvestSteelFramesManufacture,
  InvestBankFundedHouseConstructionMaterial,
  InvestInvestRDPMaterialDeliveryOrder,
  InvestSchoolRenovationsMaterialSupply,
  TwoPotRetirementSystem,
  MD76208LandRestoration,
  TolaTenderProWinningWoman,
  TolaTenderProWinningMan,
  TolaTenderProMaMkhize,
};
