//import necessary files for navbar
import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import "./NavBar.scss";
import images from "../../constants";
//import links from react router dom
//import { Link } from "react-router-dom";

//create a navbar component
const NavBar = () => {
  //used to toggle the navbar
  const [toggle, setToggle] = useState(false);
  //scroll to section
  const scroll = (id) => (e) => {
    e.preventDefault();
    document
      .getElementById(id)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logomdhorizontal} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {/* {["home", "The Bizz Phone", "Refer And Earn", "about", "contact"].map(
          (item) => (
            <li className="app__flex p-text text-white" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          )
        )} */}
        <li className="app__flex p-text text-white">
          <a href="/">Home</a>
        </li>
        <li className="app__flex p-text text-white">
          <a href="/">The Bizz Phone</a>
        </li>
        <li className="app__flex p-text text-white">
          <a href="/tender-finance" alt="Tender Finance">
            Tender Finance
          </a>
        </li>
        <li className="app__flex p-text text-white">
          <a
            href="/tolatenderpro-tender-search-bid-preparation-and-submission"
            alt="TolaTenderPro - We Do Tender Search, Bid Preparation and Submission For You!"
          >
            TolaTenderPro - Win RFQs and Tenders!
          </a>
        </li>
        <li className="app__flex p-text text-white">
          <a href="/invest" alt="Deals">
            Invest & RFQs
          </a>
        </li>
        {/* <li className="app__flex p-text text-white">
          <a href="/invoice-discounting" alt="Invoice Discounting">
            Invoice Cash-Advance&trade;
          </a>
        </li> */}
        {/* <li className="app__flex p-text text-white">
          <a
            href="/"
            alt="Customer Testimonial"
            onClick={scroll("testimonial")}
          >
            Customer Testimonial
          </a>
        </li>
        <li className="app__flex p-text text-white">
          <a href="/" onClick={scroll("comparison")}>
            Compare Us
          </a>
        </li> */}
        <li className="app__flex p-text text-white">
          <a href="/" onClick={scroll("refer")}>
            Refer And Earn
          </a>
        </li>
        <li className="app__flex p-text text-white">
          <a
            href="https://www.marketdirect.co.za/blog"
            alt="Blog and News"
            target="_blank"
          >
            Blog & News
          </a>
        </li>
        {/* <li className="app__flex p-text text-white">
          <a href="/" onClick={scroll("about")}>
            About
          </a>
        </li> */}
        <li className="app__flex p-text text-white">
          <a href="/" onClick={scroll("contact")}>
            Contact
          </a>
        </li>
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {
          //used to toggle the navbar
          toggle && (
            <motion.div
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: "easeOut" }}
            >
              <HiX onClick={() => setToggle(false)} />
              <ul>
                {/* {[
                  "home",
                  "The Bizz Phone",
                  "Refer and Earn",
                  "about",
                  "contact",
                ].map((item) => (
                  <li key={item}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  </li>
                ))} */}
                <li className="app__flex p-text text-white">
                  <a href="/">Home</a>
                </li>
                <li className="app__flex p-text text-white">
                  <a href="/">The Bizz Phone</a>
                </li>
                <li className="app__flex p-text text-white">
                  <a href="/tender-finance" alt="Tender Finance">
                    Tender Finance
                  </a>
                </li>
                <li className="app__flex p-text text-white">
                  <a
                    href="/tolatenderpro-tender-search-bid-preparation-and-submission"
                    alt="TolaTenderPro - We Do Tender Search, Bid Preparation and Submission For You!"
                  >
                    TolaTenderPro - Win RFQs and Tenders!
                  </a>
                </li>
                {/* <li className="app__flex p-text text-white">
                  <a
                    href="/bakkie-rental"
                    alt="Bakkie Rental and Furniture Removal"
                  >
                    Bakkie Rent
                  </a>
                </li> */}
                <li className="app__flex p-text text-white">
                  <a href="/" onClick={scroll("refer")}>
                    Refer And Earn
                  </a>
                </li>
                <li className="app__flex p-text text-white">
                  <a href="/invest" alt="Invest">
                    Invest & RFQs
                  </a>
                </li>
                <li className="app__flex p-text text-white">
                  <a
                    href="https://www.marketdirect.co.za/blog"
                    alt="Blog and News"
                    target="_blank"
                  >
                    Blog & News
                  </a>
                </li>
                {/* <li className="app__flex p-text text-white">
                  <a href="/invoice-discounting" alt="Invoice Discounting">
                    Invoice Cash-Advance&trade;
                  </a>
                </li> */}
                {/* <li className="app__flex p-text text-white">
                  <a href="/" onClick={scroll("pricing")}>
                    Pricing
                  </a>
                </li>
                <li className="app__flex p-text text-white">
                  <a href="/" onClick={scroll("testimonial")}>
                    Customer Testimonial
                  </a>
                </li>
                <li className="app__flex p-text text-white">
                  <a href="/" onClick={scroll("comparison")}>
                    Compare Us
                  </a>
                </li> */}
                {/* <li className="app__flex p-text text-white">
                  <a href="/" onClick={scroll("about")}>
                    About
                  </a>
                </li> */}
                <li className="app__flex p-text text-white">
                  <a href="/" onClick={scroll("contact")}>
                    Contact
                  </a>
                </li>
              </ul>
            </motion.div>
          )
        }
      </div>
    </nav>
  );
};

export default NavBar;
