//import necessary files for PricingComparison component
import React from "react";
import "./PricingComparison.scss";
import images from "../../constants";
//import react icon hi library for green checkmark
import { HiCheck, HiX } from "react-icons/hi";
//import calculateVoipPlanPrice function from utils folder
const PricingComparisonTolaTenderPro = () => {
  return (
    //Pricing comparison component
    <div className="container py-3">
      <main>
        <h2 className="display-3 text-center mb-4">
          Why Choose TolaTenderPro Over Others?
        </h2>
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th className="w-34"></th>
                <th className="w-22">TolaTenderPro</th>
                <th className="w-22">Tender Alerting Service</th>
                <th className="w-22">Tender Training</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Tender Search
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Bid Proposal Preparation
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Industry Expert Advise
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Pricing Guidance
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Pricing Research Tools + Access Our 15 000 Suppliers
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Bid Submission
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Gain Access To Faster-To-Get Subcontracting Opportunities
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Get Funding
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Outcome
                </th>
                <td>
                  More effective RFQs and tender bids, fewer mistakes, and a
                  higher likelihood of success
                </td>
                <td>
                  Increased risk of errors due to lack of support beyond alerts
                </td>
                <td>
                  Improved knowledge but no guarantee of correctly executed bids
                  because of generalized no indutry specific training.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default PricingComparisonTolaTenderPro;
